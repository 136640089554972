var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { card: "", dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [_vm._v("Additional Request")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "btn-closeAdditionalRequestDialog",
              attrs: { icon: "" },
              on: { click: _vm.closeAdditionalRequestDialog },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-text",
        { staticClass: "scroll-content-dialog px-3 pt-4" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "mb-1": "" } },
            [
              _c("v-checkbox", {
                staticClass: "que-checkbox",
                attrs: {
                  name: "timeRequest",
                  "hide-details": true,
                  value: false,
                  label: "Add Material Request",
                  color: "primary",
                },
                model: {
                  value: _vm.requestedToAddMaterialRequest,
                  callback: function ($$v) {
                    _vm.requestedToAddMaterialRequest = $$v
                  },
                  expression: "requestedToAddMaterialRequest",
                },
              }),
            ],
            1
          ),
          _vm.requestedToAddMaterialRequest
            ? _c("AddMaterialRequest", {
                ref: "addMaterialRequest",
                attrs: { "material-list": _vm.materialsStore.materials },
                model: {
                  value: _vm.additionalMaterialRequest,
                  callback: function ($$v) {
                    _vm.additionalMaterialRequest = $$v
                  },
                  expression: "additionalMaterialRequest",
                },
              })
            : _vm._e(),
          _c(
            "v-layout",
            { attrs: { wrap: "", "mb-1": "" } },
            [
              _c("v-checkbox", {
                staticClass: "que-checkbox",
                attrs: {
                  name: "timeRequest",
                  "hide-details": true,
                  value: false,
                  label: "Add Time Request",
                  color: "primary",
                },
                model: {
                  value: _vm.requestedToAddTimeRequest,
                  callback: function ($$v) {
                    _vm.requestedToAddTimeRequest = $$v
                  },
                  expression: "requestedToAddTimeRequest",
                },
              }),
            ],
            1
          ),
          _vm.requestedToAddTimeRequest
            ? _c("AddTimeRequest", {
                ref: "addTimeRequest",
                on: {
                  updateSelectedTimeRequestSlot:
                    _vm.updateSelectedTimeRequestSlot,
                },
                model: {
                  value: _vm.additionalTimeRequest,
                  callback: function ($$v) {
                    _vm.additionalTimeRequest = $$v
                  },
                  expression: "additionalTimeRequest",
                },
              })
            : _vm._e(),
          !_vm.isForContractorJobCard
            ? _c(
                "v-layout",
                { staticClass: "returnVisit", attrs: { wrap: "", "mb-3": "" } },
                [
                  _c("v-checkbox", {
                    staticClass: "que-checkbox",
                    attrs: {
                      name: "visitRequest",
                      "hide-details": true,
                      value: false,
                      label: "Add Return Visit Request",
                      color: "primary",
                    },
                    model: {
                      value: _vm.requestedToAddReturnVisitRequest,
                      callback: function ($$v) {
                        _vm.requestedToAddReturnVisitRequest = $$v
                      },
                      expression: "requestedToAddReturnVisitRequest",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.requestedToAddReturnVisitRequest
            ? _c("AddReturnVisitRequest", {
                ref: "addReturnVisitRequest",
                attrs: { "return-visit-type-list": _vm.returnVisitTypeList },
                on: {
                  updateVisitHourMinutesValidation:
                    _vm.updateVisitHourMinutesValidation,
                  openConfigureDropdown: _vm.openConfigureDropdown,
                  updateReturnVisitAtUtc: _vm.updateReturnVisitAtUtc,
                },
                model: {
                  value: _vm.additionalReturnVisitRequest,
                  callback: function ($$v) {
                    _vm.additionalReturnVisitRequest = $$v
                  },
                  expression: "additionalReturnVisitRequest",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-actions",
        { staticClass: "px-3" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", flat: "flat" },
              nativeOn: {
                click: function ($event) {
                  return _vm.closeAdditionalRequestDialog.apply(null, arguments)
                },
              },
            },
            [_vm._v("Close")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-0 btn-submitAdditionalRequest",
              attrs: {
                color: "primary",
                disabled:
                  (!_vm.requestedToAddTimeRequest &&
                    !_vm.requestedToAddMaterialRequest &&
                    !_vm.requestedToAddReturnVisitRequest) ||
                  _vm.onSubmitLoading ||
                  !_vm.isVisitTimeValid,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.submitAdditionalRequest.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Submit ")]
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "700",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.configureDropdownDialog,
            callback: function ($$v) {
              _vm.configureDropdownDialog = $$v
            },
            expression: "configureDropdownDialog",
          },
        },
        [
          _c("ConfigureDropdown", {
            ref: "configureDropdownDialog",
            attrs: { "record-type": _vm.configureDropdownType },
            on: {
              CloseConfigureDropdownDialog: _vm.onConfigureDropdownDialogClose,
            },
          }),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }