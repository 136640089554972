var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.callAlert.hariSummary
    ? _c(
        "v-container",
        { staticClass: "wrapper" },
        [
          _vm.callAnswered
            ? _c(
                "div",
                {
                  staticClass: "close-modal",
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                },
                [_c("v-icon", { attrs: { color: "red" } }, [_vm._v("close")])],
                1
              )
            : _vm._e(),
          _vm.showDetails
            ? _c(
                "v-layout",
                {
                  staticClass: "px-4 py-4",
                  class: _vm.policyMatchSuccess
                    ? "policy-score-match--success"
                    : "policy-score-match--failed",
                  attrs: { row: "" },
                },
                [
                  _c("v-layout", { attrs: { column: "" } }, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.isWIPCall ? "JobId" : "Policy")),
                    ]),
                    _c("div", [_vm._v("Surname")]),
                    _c("div", [_vm._v("Postcode")]),
                    _vm.calculatedCallScore.addressLine
                      ? _c("div", [_vm._v("Address Line 1")])
                      : _vm._e(),
                  ]),
                  _c("v-layout", { attrs: { column: "" } }, [
                    _c(
                      "div",
                      { staticClass: "font-weight-bold text-uppercase" },
                      [_vm._v(_vm._s(_vm.isWIPCall ? "Matched" : "Found"))]
                    ),
                    _c("div", { staticClass: "font-weight-bold user-detail" }, [
                      _vm._v(_vm._s(_vm.calculatedCallScore.surname) + "%"),
                    ]),
                    _c("div", { staticClass: "font-weight-bold user-detail" }, [
                      _vm._v(_vm._s(_vm.calculatedCallScore.postcode) + "%"),
                    ]),
                    _vm.calculatedCallScore.addressLine
                      ? _c(
                          "div",
                          { staticClass: "font-weight-bold user-detail" },
                          [
                            _vm._v(
                              _vm._s(_vm.calculatedCallScore.addressLine) + "%"
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("v-layout", { attrs: { column: "" } }, [
                    _c("div", { staticClass: "user-detail" }, [
                      _vm._v(_vm._s(_vm.callAlert.hariSummary.policyReference)),
                    ]),
                    _c("div", { staticClass: "user-detail" }, [
                      _vm._v(_vm._s(_vm.callAlert.hariSummary.surname)),
                    ]),
                    _c("div", { staticClass: "user-detail" }, [
                      _vm._v(_vm._s(_vm.callAlert.hariSummary.postcode)),
                    ]),
                    _vm.calculatedCallScore.addressLine
                      ? _c("div", { staticClass: "user-detail" }, [
                          _vm._v(_vm._s(_vm.callAlert.hariSummary.addressLine)),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "v-layout",
                    { staticClass: "align-end", attrs: { column: "" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "circle-icon mr-1",
                          class: _vm.policyMatchSuccess
                            ? "text-success"
                            : "text-error",
                          style: `border-color: ${
                            _vm.policyMatchSuccess ? "green" : "red"
                          }`,
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.calculatedCallScore.overallMatch) +
                              " "
                          ),
                        ]
                      ),
                      _c("h6", { staticClass: "text-uppercase" }, [
                        _vm._v("Overall Score"),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _c(
                "v-layout",
                {
                  staticClass:
                    "px-4 py-4 justify-center policy-score-match--failed",
                  attrs: { row: "" },
                },
                [
                  _c("h3", { staticClass: "text-error" }, [
                    _vm._v(_vm._s(_vm.errorText)),
                  ]),
                ]
              ),
          _c("v-layout", { staticClass: "px-4", attrs: { column: "" } }, [
            _c("div", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.callAlert.clientDetails)),
            ]),
            _c("div", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.callAlert.hariSummary.callType)),
            ]),
            _c("div", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.callAlert.hariSummary.policyScheduleName)),
            ]),
            _c("div", [
              _vm._v('"' + _vm._s(_vm.callAlert.hariSummary.callReason) + '"'),
            ]),
          ]),
          _vm.callAlert.incomingAlertType !== "Callback Request"
            ? _c(
                "v-layout",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-layout",
                    { staticClass: "align-center justify-center" },
                    [
                      _c("div", { staticClass: "intro-message" }, [
                        _vm._v(_vm._s(_vm.callAlert.additionalDetails)),
                      ]),
                    ]
                  ),
                  _c("v-layout", { staticClass: "align-center justify-end" }, [
                    _vm.showAnswerButton
                      ? _c(
                          "div",
                          {
                            staticClass: "circle-icon call-vibration",
                            on: { click: _vm.answerCall },
                          },
                          [
                            _c("v-icon", { attrs: { color: "green" } }, [
                              _vm._v("call"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _c("v-layout", { staticClass: "pa-4" }, [
                _c(
                  "div",
                  {
                    staticClass: "circle-icon call-vibration",
                    class: _vm.policyMatchSuccess
                      ? "text-success"
                      : "text-error",
                    on: { click: _vm.initiateCallback },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "green" },
                        on: { click: _vm.answerCall },
                      },
                      [_vm._v("Start Call")]
                    ),
                  ],
                  1
                ),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }