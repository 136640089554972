var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.apps.length
    ? _c(
        "v-menu",
        {
          attrs: { "offset-y": "", left: "", "close-on-content-click": true },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g({ attrs: { icon: "", large: "" } }, on),
                      [
                        _c(
                          "v-avatar",
                          { attrs: { size: "38px" } },
                          [_c("v-icon", [_vm._v("apps")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            3207326425
          ),
        },
        [
          _c(
            "v-card",
            { staticStyle: { width: "500px" }, attrs: { elevation: 16 } },
            [
              _c(
                "div",
                { staticClass: "application-list" },
                _vm._l(_vm.apps, function (app) {
                  return _c(
                    "div",
                    {
                      key: app.id,
                      staticClass: "application",
                      attrs: { title: app.description },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "application__button",
                          attrs: { href: app.applicationUrl },
                        },
                        [
                          _c("v-img", {
                            staticClass: "mt-3",
                            attrs: { src: app.logoUrl, width: 64, height: 64 },
                          }),
                          _c(
                            "v-card-title",
                            { staticClass: "text-subtitle-1" },
                            [_vm._v(" " + _vm._s(app.friendlyName) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }